import { graphql } from '@server/gql';

export const deleteAnonymousWishlistItemMutation = graphql(/* GraphQL */ `
    mutation deleteAnonymousWishlistItem(
        $wishlistId: Int!
        $wishlistItemId: Int!
        $wishlistToken: String!
    ) {
        deleteAnonymousWishlistItem(
            wishlistId: $wishlistId
            wishlistItemId: $wishlistItemId
            wishlistToken: $wishlistToken
        ) {
            isError
            data {
                code
                message
                success
            }
            error {
                __typename
                ...problemDetails
                ...genericResponse
            }
        }
    }
`);
