import { envConfig, SUPPORTED_LOCALES } from '@/config/env';

/**
 * @deprecated - use ALLOWED_LOCALES instead.
 */
export const isLocale = (locale?: string) => {
    if (!locale) {
        return false;
    }

    if (envConfig.NEXT_PUBLIC_APP_LOCALE !== undefined) {
        return locale === envConfig.NEXT_PUBLIC_APP_LOCALE;
    }

    const foundLocale = locale.match(
        /^([A-Za-z]{2}|[A-Za-z]{2}-[A-Za-z]{2}|eu)$/,
    );

    return (
        Boolean(foundLocale) && SUPPORTED_LOCALES.includes(locale.toLowerCase())
    );
};
