import { graphql } from '@server/gql';

export const deleteWishlistTagMutation = graphql(/* GraphQL */ `
    mutation deleteWishlistTag(
        $tagId: Int!
        $deleteUnderlyingItems: Boolean!
        $token: String!
    ) {
        deleteWishlistTag(
            tagId: $tagId
            deleteUnderlyingItems: $deleteUnderlyingItems
            token: $token
        ) {
            isError
            data {
                code
                message
                success
            }
            error {
                __typename
                ...problemDetails
                ...genericResponse
            }
        }
    }
`);
