import { service } from '@server/client';
import type {
    AddWishlistItemTagMutation,
    AddWishlistItemTagMutationVariables,
    WishlistAddTagAddItemDtoInput,
} from '@server/gql/graphql';
import { addWishlistItemTagMutation } from '@server/queries/wishlist';

export const addWishlistItemTag = async ({
    token,
    payload,
}: {
    token: string;
    payload: WishlistAddTagAddItemDtoInput;
}) => {
    try {
        const response = await service<
            AddWishlistItemTagMutation,
            AddWishlistItemTagMutationVariables
        >({
            query: addWishlistItemTagMutation,
            variables: {
                token,
                payload,
            },
        });
        return response;
    } catch (e: any) {
        console.group(
            ...e.response.errors.map((err: any) => console.debug(err)),
            console.debug('(in addWishlistItemTag)'),
        );
        return e.response.data as AddWishlistItemTagMutation;
    }
};
