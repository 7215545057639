'use client';

import type { MarketProps, MarketStatePayload } from '@context/common';
import { languages, markets } from '@context/common';
import { useQueryClient } from '@tanstack/react-query';
import { deleteCookie, getCookie, hasCookie, setCookie } from 'cookies-next';
import { usePathname, useRouter } from 'next/navigation';
import { useCallback, useMemo } from 'react';

import config from '@/config/locale';
import { COOKIE_MAX_AGE, getAppLocale } from '@/utils/const';
import { parseParams } from '@/utils/params';

export const useMarket = (): MarketProps => {
    const router = useRouter();
    const pathname = usePathname();
    const formatter = new Intl.NumberFormat('sv-SE', { useGrouping: true });
    const queryClient = useQueryClient();
    const {
        locale = '',
        market,
        language,
        contentfulLocale,
        cleanSlug,
    } = parseParams({ pathname });

    const suggested = useMemo(() => {
        if (!hasCookie('ng_suggestion')) return undefined;
        const cookie = getCookie('ng_suggestion');
        if (!cookie) return undefined;
        return markets[cookie.toString()];
    }, []);

    const dismissed = useMemo(() => {
        if (!hasCookie('ng_dismissed')) return false;
        const cookie = getCookie('ng_dismissed');
        return Boolean(cookie);
    }, []);

    /**
     * @deprecated use CheckoutCountrySelect.tsx and handleChange instead
     */
    const onSave = useCallback(
        (payload: MarketStatePayload) => {
            if (payload.link) {
                router.push(payload.link);
            } else if (payload.market && payload.language) {
                const _locale = getAppLocale(payload.market, payload.language);
                if (hasCookie('ng_suggestion')) deleteCookie('ng_suggestion');
                if (hasCookie('ng_dismissed')) deleteCookie('ng_dismissed');
                setCookie('ng_market', payload.market, {
                    maxAge: COOKIE_MAX_AGE,
                });
                setCookie('ng_language', payload.language, {
                    maxAge: COOKIE_MAX_AGE,
                });
                if (locale !== _locale) {
                    // Using router.push here breaks imbox-widget, hence the window.location.href
                    if (cleanSlug === '/checkout') {
                        window.location.href = `/${_locale}/checkout`;
                    } else {
                        window.location.href = `/${_locale}`;
                    }
                }
            }
        },
        [router, cleanSlug, locale],
    );

    const onClose = () => {
        if (hasCookie('ng_suggestion')) {
            setCookie('ng_dismissed', true, {
                maxAge: COOKIE_MAX_AGE,
            });
        }
        queryClient.setQueryData(['marketSelector'], false);
    };

    return {
        onSave,
        onClose,
        state: {
            locale: locale !== '' ? locale : (config.locale ?? ''),
            market: markets[market],
            markets,
            language: languages[language],
            languages,
            suggested,
            formatter,
            dismissed,
            contentfulLocale,
        },
    };
};
