import { service } from '@server/client';
import type {
    DeleteWishlistItemTagMutation,
    DeleteWishlistItemTagMutationVariables,
} from '@server/gql/graphql';
import { deleteWishlistItemTagMutation } from '@server/queries/wishlist';

export const deleteWishlistItemTag = async ({
    token,
    tagId,
    wishlistItemId,
}: {
    token: string;
    tagId: number;
    wishlistItemId: number;
}) => {
    try {
        const response = await service<
            DeleteWishlistItemTagMutation,
            DeleteWishlistItemTagMutationVariables
        >({
            query: deleteWishlistItemTagMutation,
            variables: {
                token,
                tagId,
                wishlistItemId,
            },
        });
        console.log('RESPONSE');
        if (!response) throw new Error('');
        console.log('RESPONSE EXISTS');
        if (response?.deleteWishlistItemTag?.isError) {
            console.log('RESPONSE EXISTS');
            const { error } = response.deleteWishlistItemTag;
            if (error?.__typename === 'GenericResponse') {
                throw new Error(error.message ?? 'Error');
            } else {
                throw new Error(error?.detail ?? error?.title ?? 'Error');
            }
        }
        return response;
    } catch (e: any) {
        console.group(
            ...e.response.errors.map((err: any) => console.debug(err)),
            console.debug('(in deleteWishlistItemTag)'),
        );
        return e;
    }
};
