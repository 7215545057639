import { service } from '@server/client';
import type {
    GetWishlistItemQuery,
    GetWishlistItemQueryVariables,
} from '@server/gql/graphql';
import { getWishlistItemQuery } from '@server/queries/wishlist';

export const getWishlistItem = async ({
    token,
    productId,
}: {
    token: string;
    productId: string;
}) => {
    try {
        const response = await service<
            GetWishlistItemQuery,
            GetWishlistItemQueryVariables
        >({
            query: getWishlistItemQuery,
            variables: {
                token,
                productId,
            },
        });
        return response;
    } catch (e: any) {
        console.group(
            ...e.response.errors.map((err: any) => console.debug(err)),
            console.debug('(in getWishlistItem)'),
        );
        return e.response.data as GetWishlistItemQuery;
    }
};
