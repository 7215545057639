import type { SVGProps } from 'react';

export default function NoFlag(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1100 800"
            preserveAspectRatio="xMidYMid slice"
            {...props}>
            <path fill="#ef2b2d" d="M0 0h1100v800H0z" />
            <path fill="#fff" d="M300 0h200v800H300z" />
            <path fill="#fff" d="M0 300h1100v200H0z" />
            <path fill="#002868" d="M350 0h100v800H350z" />
            <path fill="#002868" d="M0 350h1100v100H0z" />
        </svg>
    );
}
