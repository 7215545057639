'use client';

import { useMarket } from '@context/marketContext';
import type { MouseEvent } from 'react';
import { useCallback, useMemo } from 'react';

import { envConfig } from '@/config/env';
import { cn } from '@/lib/utils';
import { getAppLocale } from '@/utils/const';
import { isLocale } from '@/utils/isLocale';

import MarketSelectorOption from './MarketSelectorOption';

export default function MarketSelectorList() {
    const { state, onSave } = useMarket();

    const markets = useMemo(
        () =>
            Object.values(state.markets).sort((a, b) =>
                a.label.localeCompare(b.label),
            ),
        [state.markets],
    );

    const onClick = useCallback(
        (event: MouseEvent<HTMLLIElement>) => {
            event.preventDefault();
            const market = event.currentTarget.getAttribute('data-market');
            const language = event.currentTarget.getAttribute('data-language');

            if (!market || !language) {
                return;
            }

            const isShortLocale = ['eu', 'dk', 'fi', 'nl'].includes(market);
            const targetLocale = isShortLocale
                ? market
                : `${language}-${market}`;

            if (isLocale(targetLocale)) {
                if (market === 'dk' && envConfig.NEXT_PUBLIC_DK !== 'true') {
                    onSave({ link: 'https://www.nordiskagalleriet.dk' });
                    return;
                }

                if (market === 'fi' && envConfig.NEXT_PUBLIC_FI !== 'true') {
                    onSave({ link: 'https://www.nordiskagalleriet.fi' });
                    return;
                }
            }

            if (market === 'se') {
                onSave({ link: 'https://www.nordiskagalleriet.se' });
                return;
            }

            if (market === 'no') {
                onSave({ link: 'https://www.nordiskagalleriet.no' });
                return;
            }

            if (state.market.code === 'no' || state.market.code === 'se') {
                onSave({
                    link: `https://www.no-ga.com/${getAppLocale(market, language)}`,
                });
                return;
            }

            onSave({ market, language });
        },
        [onSave, state.market.code],
    );

    return (
        <ul className="flex w-full flex-col divide-y">
            {markets.map((market) => {
                const language =
                    state.languages[market.languages.at(0) ?? 'en'];
                return (
                    <MarketSelectorOption
                        key={market.code}
                        market={market}
                        language={language}
                        data-market={market.code}
                        data-language={language.code}
                        as="li"
                        role="button"
                        onClick={onClick}
                        className={cn(
                            'px-6 py-4',
                            market.code === state.market.code && 'bg-gray-100',
                        )}
                    />
                );
            })}
        </ul>
    );
}
