import type { SVGProps } from 'react';

export default function AtFlag(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 900 600"
            preserveAspectRatio="xMidYMid slice"
            {...props}>
            <path fill="#ed2939" d="M0 0h900v600H0z" />
            <path fill="#fff" d="M0 200h900v200H0z" />
        </svg>
    );
}
