import { graphql } from '@server/gql';

export const getAnonymousWishlistItemQuery = graphql(/* GraphQL */ `
    query getAnonymousWishlistItem(
        $wishlistId: Int!
        $wishlistToken: String!
        $productId: String!
    ) {
        getAnonymousWishlistItem(
            wishlistId: $wishlistId
            wishlistToken: $wishlistToken
            productId: $productId
        ) {
            isError
            data {
                id
                productId
                variantId
                wishlistId
            }
            error {
                __typename
                ...problemDetails
                ...genericResponse
            }
        }
    }
`);
