import { graphql } from '@server/gql';

export const createWishlistTagShareLinkMutation = graphql(/* GraphQL */ `
    mutation createWishlistTagShareLink($tagId: Int!, $token: String!) {
        createWishlistTagShareLink(tagId: $tagId, token: $token) {
            isError
            data {
                id
                name
                shareLink
            }
            error {
                __typename
                ...problemDetails
                ...genericResponse
            }
        }
    }
`);
