import Flag from '@components/Flag';
import type { Language, Market } from '@context/common';
import type { ComponentPropsWithoutRef, ElementType } from 'react';

import { cn } from '@/lib/utils';

type MarketSelectorOptionProps<T extends ElementType> = {
    as?: T;
    market: Market;
    language: Language;
};

export default function MarketSelectorOption<T extends ElementType>(
    props: MarketSelectorOptionProps<T> &
        Omit<ComponentPropsWithoutRef<T>, keyof MarketSelectorOptionProps<T>>,
) {
    const { as, market, language, className, ...rest } = props;
    const Component = as || 'li';

    return (
        <Component
            className={cn(
                'flex cursor-pointer items-center p-3 space-x-2',
                className,
            )}
            key={market.code}
            {...rest}>
            <Flag
                code={market.code}
                className="h-4 w-4 flex-shrink-0 rounded-full border border-black object-cover"
            />
            <span className="flex-1">
                <b>{market.label}</b>&nbsp;({language?.label})
            </span>
            <span className="flex-shrink-0 uppercase text-gray-400">
                {market.currency}
            </span>
        </Component>
    );
}
