import { service } from '@server/client';
import type {
    GetAnonymousWishlistQuery,
    GetAnonymousWishlistQueryVariables,
} from '@server/gql/graphql';
import { getAnonymousWishlistQuery } from '@server/queries/anonymousWishlist';

export const getAnonymousWishlist = async ({
    wishlistId,
    wishlistToken,
}: {
    wishlistId: number;
    wishlistToken: string;
}) => {
    try {
        const response = await service<
            GetAnonymousWishlistQuery,
            GetAnonymousWishlistQueryVariables
        >({
            query: getAnonymousWishlistQuery,
            variables: {
                wishlistId,
                wishlistToken,
            },
        });
        return response;
    } catch (e: any) {
        console.group(
            ...e.response.errors.map((err: any) => console.debug(err)),
            console.debug('(in getAnonymousWishlist)'),
        );
        return e.response.data as GetAnonymousWishlistQuery;
    }
};
