import type { SVGProps } from 'react';

export default function EsFlag(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 750 500"
            preserveAspectRatio="xMidYMid slice"
            {...props}>
            <path fill="#c60b1e" d="M0 0h750v500H0z" />
            <path fill="#ffc400" d="M0 125h750v250H0z" />
        </svg>
    );
}
