import type { SVGProps } from 'react';

export default function FrFlag(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 3 2"
            preserveAspectRatio="xMidYMid slice"
            {...props}>
            <path fill="#ED2939" d="M0 0h3v2H0z" />
            <path fill="#fff" d="M0 0h2v2H0z" />
            <path fill="#002395" d="M0 0h1v2H0z" />
        </svg>
    );
}
