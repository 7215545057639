import { graphql } from '@server/gql';

export const getWishlistTagsQuery = graphql(/* GraphQL */ `
    query getWishlistTags($token: String!) {
        getWishlistTags(token: $token) {
            isError
            data {
                id
                name
                shareLink
            }
            error {
                __typename
                ...problemDetails
                ...genericResponse
            }
        }
    }
`);
