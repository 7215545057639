import { service } from '@server/client';
import type {
    DeleteWishlistItemMutation,
    DeleteWishlistItemMutationVariables,
} from '@server/gql/graphql';
import { deleteWishlistItemMutation } from '@server/queries/wishlist';

export const deleteWishlistItem = async ({
    token,
    wishlistItemId,
}: {
    token: string;
    wishlistItemId: number;
}) => {
    try {
        const response = await service<
            DeleteWishlistItemMutation,
            DeleteWishlistItemMutationVariables
        >({
            query: deleteWishlistItemMutation,
            variables: {
                token,
                wishlistItemId,
            },
        });
        return response;
    } catch (e: any) {
        console.group(
            ...e.response.errors.map((err: any) => console.debug(err)),
            console.debug('(in deleteWishlistItem)'),
        );
        return e.response.data as DeleteWishlistItemMutation;
    }
};
