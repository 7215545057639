import { service } from '@server/client';
import type {
    TagInputDtoInput,
    UpdateWishlistTagMutation,
    UpdateWishlistTagMutationVariables,
} from '@server/gql/graphql';
import { updateWishlistTagMutation } from '@server/queries/wishlist';

export const updateWishlistTag = async ({
    token,
    payload,
}: {
    token: string;
    payload: TagInputDtoInput;
}) => {
    try {
        const response = await service<
            UpdateWishlistTagMutation,
            UpdateWishlistTagMutationVariables
        >({
            query: updateWishlistTagMutation,
            variables: {
                token,
                payload,
            },
        });
        return response;
    } catch (e: any) {
        console.group(
            ...e.response.errors.map((err: any) => console.debug(err)),
            console.debug('(in updateWishlistTag)'),
        );
        return e.response.data as UpdateWishlistTagMutation;
    }
};
