import { graphql } from '@server/gql';

export const copyAnonymousWishlistToWishlistMutation = graphql(/* GraphQL */ `
    mutation copyAnonymousWishlistToWishlist(
        $wishlistId: Int!
        $wishlistToken: String!
        $token: String!
    ) {
        copyAnonymousWishlistToWishlist(
            wishlistItemId: $wishlistId
            wishlistToken: $wishlistToken
            token: $token
        ) {
            isError
            data {
                code
                message
                success
            }
            error {
                __typename
                ...problemDetails
                ...genericResponse
            }
        }
    }
`);
