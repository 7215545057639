import type { SVGProps } from 'react';

export default function SeFlag(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 10"
            preserveAspectRatio="xMidYMid slice"
            {...props}>
            <path fill="#006aa7" d="M0 0h16v10H0z" />
            <path fill="#fecc00" d="M5 0h2v10H5z" />
            <path fill="#fecc00" d="M0 4h16v2H0z" />
        </svg>
    );
}
