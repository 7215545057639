import { graphql } from '@server/gql';

export const addAnonymousWishlistItemMutation = graphql(/* GraphQL */ `
    mutation addAnonymousWishlistItem(
        $wishlistToken: String
        $payload: WishlistAnonymousAddItemDtoInput!
    ) {
        addAnonymousWishlistItem(
            wishlistToken: $wishlistToken
            payload: $payload
        ) {
            isError
            data {
                id
                productId
                token
                variantId
                wishlistId
            }
            error {
                __typename
                ...problemDetails
                ...genericResponse
            }
        }
    }
`);
