import type { ComponentProps } from 'react';

import AtFlag from './AtFlag';
import BeFlag from './BeFlag';
import DeFlag from './DeFlag';
import DkFlag from './DkFlag';
import EsFlag from './EsFlag';
import EUFlag from './EUFlag';
import FiFlag from './FiFlag';
import FrFlag from './FrFlag';
import GbFlag from './GbFlag';
import NlFlag from './NlFlag';
import NoFlag from './NoFlag';
import SeFlag from './SeFlag';

type FlagProps = ComponentProps<'svg'> & {
    code: string;
};

export default function Flag({ code, ...props }: FlagProps) {
    switch (code.toLowerCase()) {
        case 'de':
            return <DeFlag {...props} />;
        case 'se':
            return <SeFlag {...props} />;
        case 'eu':
            return <EUFlag {...props} />;
        case 'es':
            return <EsFlag {...props} />;
        case 'fr':
            return <FrFlag {...props} />;
        case 'be':
            return <BeFlag {...props} />;
        case 'at':
            return <AtFlag {...props} />;
        case 'no':
            return <NoFlag {...props} />;
        case 'gb':
            return <GbFlag {...props} />;
        case 'fi':
            return <FiFlag {...props} />;
        case 'dk':
            return <DkFlag {...props} />;
        case 'nl':
            return <NlFlag {...props} />;
        default:
            return null;
    }
}
