import { graphql } from '@server/gql';

export const getWishlistQuery = graphql(/* GraphQL */ `
    query getWishlist($token: String!, $page: Int, $pageSize: Int) {
        getWishlist(page: $page, pageSize: $pageSize, token: $token) {
            __typename
            isError
            data {
                __typename
                metadata {
                    totalItems
                    totalPages
                }
                wishlistItems {
                    id
                    productId
                    variantId
                    tags {
                        id
                        name
                        shareLink
                    }
                }
            }
            error {
                __typename
                ...problemDetails
                ...genericResponse
            }
        }
    }
`);
