import { service } from '@server/client';
import type {
    GetAnonymousWishlistItemQuery,
    GetAnonymousWishlistItemQueryVariables,
} from '@server/gql/graphql';
import { getAnonymousWishlistItemQuery } from '@server/queries/anonymousWishlist';

export const getAnonymousWishlistItem = async ({
    wishlistId,
    wishlistToken,
    productId,
}: {
    wishlistId: number;
    wishlistToken: string;
    productId: string;
}) => {
    try {
        const response = await service<
            GetAnonymousWishlistItemQuery,
            GetAnonymousWishlistItemQueryVariables
        >({
            query: getAnonymousWishlistItemQuery,
            variables: {
                wishlistId,
                wishlistToken,
                productId,
            },
        });
        return response;
    } catch (e: any) {
        console.group(
            ...e.response.errors.map((err: any) => console.debug(err)),
            console.debug('(in getAnonymousWishlistItem)'),
        );
        return e.response.data as GetAnonymousWishlistItemQuery;
    }
};
