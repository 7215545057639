import { service } from '@server/client';
import type {
    CreateWishlistTagShareLinkMutation,
    CreateWishlistTagShareLinkMutationVariables,
} from '@server/gql/graphql';
import { createWishlistTagShareLinkMutation } from '@server/queries/wishlist';

export const createWishlistTagShareLink = async ({
    token,
    tagId,
}: {
    token: string;
    tagId: number;
}) => {
    try {
        const response = await service<
            CreateWishlistTagShareLinkMutation,
            CreateWishlistTagShareLinkMutationVariables
        >({
            query: createWishlistTagShareLinkMutation,
            variables: {
                token,
                tagId,
            },
        });
        return response;
    } catch (e: any) {
        console.group(
            ...e.response.errors.map((err: any) => console.debug(err)),
            console.debug('(in createWishlistTagShareLink)'),
        );
        return e.response.data as CreateWishlistTagShareLinkMutation;
    }
};
