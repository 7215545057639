import { service } from '@server/client';
import type {
    GetWishlistByTagQuery,
    GetWishlistByTagQueryVariables,
} from '@server/gql/graphql';
import { getWishlistByTagQuery } from '@server/queries/wishlist';

export const getWishlistByTag = async ({
    token,
    tagId,
}: {
    token: string;
    tagId: number;
}) => {
    try {
        const response = await service<
            GetWishlistByTagQuery,
            GetWishlistByTagQueryVariables
        >({
            query: getWishlistByTagQuery,
            variables: {
                token,
                tagId,
            },
        });
        return response;
    } catch (e: any) {
        console.group(
            ...e.response.errors.map((err: any) => console.debug(err)),
            console.debug('(in getWishlistByTag)'),
        );
        return e.response.data as GetWishlistByTagQuery;
    }
};
