import { graphql } from '@server/gql';

export const getWishlistByTagQuery = graphql(/* GraphQL */ `
    query getWishlistByTag($tagId: Int!, $token: String!) {
        getWishlistByTag(tagId: $tagId, token: $token) {
            __typename
            isError
            data {
                __typename
                id
                productId
                variantId
                tags {
                    id
                    name
                    shareLink
                }
            }
            error {
                __typename
                ...problemDetails
                ...genericResponse
            }
        }
    }
`);
