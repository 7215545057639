import { service } from '@server/client';
import type {
    DeleteAnonymousWishlistItemMutation,
    DeleteAnonymousWishlistItemMutationVariables,
} from '@server/gql/graphql';
import { deleteAnonymousWishlistItemMutation } from '@server/queries/anonymousWishlist';

export const deleteAnonymousWishlistItem = async ({
    wishlistId,
    wishlistItemId,
    wishlistToken,
}: {
    wishlistId: number;
    wishlistItemId: number;
    wishlistToken: string;
}) => {
    try {
        const response = await service<
            DeleteAnonymousWishlistItemMutation,
            DeleteAnonymousWishlistItemMutationVariables
        >({
            query: deleteAnonymousWishlistItemMutation,
            variables: {
                wishlistId,
                wishlistItemId,
                wishlistToken,
            },
        });
        return response;
    } catch (e: any) {
        console.group(
            ...e.response.errors.map((err: any) => console.debug(err)),
            console.debug('(in deleteAnonymousWishlistItem)'),
        );
        return e.response.data as DeleteAnonymousWishlistItemMutation;
    }
};
