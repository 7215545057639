import { graphql } from '@server/gql';

export const getSharedWishlistTagQuery = graphql(/* GraphQL */ `
    query getSharedWishlistTag($shareToken: String!) {
        getSharedWishlistTag(shareToken: $shareToken) {
            __typename
            isError
            data {
                __typename
                id
                name
                shareLink
                wishlistItems {
                    productId
                    variantId
                }
            }
            error {
                __typename
                ...problemDetails
                ...genericResponse
            }
        }
    }
`);
