export default function getContentfulLocaleByLanguage(language?: string) {
    switch (language) {
        case 'sv':
            return 'sv-SE';
        case 'nb':
            return 'nb-NO';
        case 'fi':
            return 'fi-FI';
        case 'da':
            return 'da-DK';
        case 'nl':
            return 'nl-NL';
        default:
            return 'en-GB';
    }
}
