import { graphql } from '@server/gql';

export const deleteWishlistItemMutation = graphql(/* GraphQL */ `
    mutation deleteWishlistItem($wishlistItemId: Int!, $token: String!) {
        deleteWishlistItem(wishlistItemId: $wishlistItemId, token: $token) {
            isError
            data {
                code
                message
                success
            }
            error {
                __typename
                ...problemDetails
                ...genericResponse
            }
        }
    }
`);
