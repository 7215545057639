import type { SVGProps } from 'react';

export default function NlFlag(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 9 6"
            preserveAspectRatio="xMidYMid slice"
            {...props}>
            <path fill="#21468B" d="M0 0h9v6H0z" />
            <path fill="#FFF" d="M0 0h9v4H0z" />
            <path fill="#AE1C28" d="M0 0h9v2H0z" />
        </svg>
    );
}
