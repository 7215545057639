import { graphql } from '@server/gql';

export const updateWishlistTagMutation = graphql(/* GraphQL */ `
    mutation updateWishlistTag(
        $payload: TagInputWithIdDtoInput!
        $token: String!
    ) {
        updateWishlistTag(payload: $payload, token: $token) {
            isError
            data {
                ...genericResponse
            }
            error {
                __typename
                ...problemDetails
                ...genericResponse
            }
        }
    }
`);
