import { envConfig } from '@/config/env';

export default function getSearchEngineUrlByMarket(market?: string) {
    switch (market) {
        case 'dk':
            return envConfig.NEXT_PUBLIC_SEARCH_ENGINE_URL_DK;
        case 'fi':
            return envConfig.NEXT_PUBLIC_SEARCH_ENGINE_URL_FI;
        case 'no':
            return envConfig.NEXT_PUBLIC_SEARCH_ENGINE_URL_NO;
        case 'se':
            return envConfig.NEXT_PUBLIC_SEARCH_ENGINE_URL_SE;
        case 'nl':
            return envConfig.NEXT_PUBLIC_SEARCH_ENGINE_URL_NL;
        default:
            return envConfig.NEXT_PUBLIC_SEARCH_ENGINE_URL;
    }
}
