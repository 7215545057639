import { graphql } from '@server/gql';

export const getAnonymousWishlistQuery = graphql(/* GraphQL */ `
    query getAnonymousWishlist(
        $wishlistId: Int!
        $wishlistToken: String!
        $page: Int
        $pageSize: Int
    ) {
        getAnonymousWishlist(
            wishlistId: $wishlistId
            wishlistToken: $wishlistToken
            page: $page
            pageSize: $pageSize
        ) {
            __typename
            isError
            data {
                __typename
                metadata {
                    totalItems
                    totalPages
                }
                wishlistItems {
                    id
                    productId
                    variantId
                    wishlistId
                }
            }
            error {
                __typename
                ...problemDetails
                ...genericResponse
            }
        }
    }
`);
