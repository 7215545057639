import type { SVGProps } from 'react';

export default function DkFlag(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 370 280"
            preserveAspectRatio="xMidYMid slice"
            {...props}>
            <path fill="#c60c30" d="M0 0h370v280H0z" />
            <path fill="#fff" d="M120 0h40v280h-40z" />
            <path fill="#fff" d="M0 120h370v40H0z" />
        </svg>
    );
}
