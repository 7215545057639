import { service } from '@server/client';
import type {
    CopyAnonymousWishlistToWishlistMutation,
    CopyAnonymousWishlistToWishlistMutationVariables,
} from '@server/gql/graphql';
import { copyAnonymousWishlistToWishlistMutation } from '@server/queries/wishlist';

export const copyAnonymousWishlistToWishlist = async ({
    token,
    wishlistId,
    wishlistToken,
}: {
    token: string;
    wishlistId: number;
    wishlistToken: string;
}) => {
    try {
        const response = await service<
            CopyAnonymousWishlistToWishlistMutation,
            CopyAnonymousWishlistToWishlistMutationVariables
        >({
            query: copyAnonymousWishlistToWishlistMutation,
            variables: {
                token,
                wishlistId,
                wishlistToken,
            },
        });
        return response;
    } catch (e: any) {
        console.group(
            ...e.response.errors.map((err: any) => console.debug(err)),
            console.debug('(in copyAnonymousWishlistToWishlist)'),
        );
        return e.response.data as CopyAnonymousWishlistToWishlistMutation;
    }
};
