import { service } from '@server/client';
import type {
    GetWishlistQuery,
    GetWishlistQueryVariables,
} from '@server/gql/graphql';
import { getWishlistQuery } from '@server/queries/wishlist';

export const getWishlist = async ({
    token,
    page,
    pageSize,
}: {
    token: string;
    page?: number;
    pageSize?: number;
}) => {
    try {
        const response = await service<
            GetWishlistQuery,
            GetWishlistQueryVariables
        >({
            query: getWishlistQuery,
            variables: {
                token,
                page,
                pageSize,
            },
        });
        return response;
    } catch (e: any) {
        console.group(
            ...e.response.errors.map((err: any) => console.debug(err)),
            console.debug('(in getWishlist)'),
        );
        return e.response.data as GetWishlistQuery;
    }
};
