import { service } from '@server/client';
import type {
    GetSharedWishlistTagQuery,
    GetSharedWishlistTagQueryVariables,
} from '@server/gql/graphql';
import { getSharedWishlistTagQuery } from '@server/queries/wishlist';

export const getSharedWishlistTag = async ({
    shareToken,
}: {
    shareToken: string;
}) => {
    try {
        const response = await service<
            GetSharedWishlistTagQuery,
            GetSharedWishlistTagQueryVariables
        >({
            query: getSharedWishlistTagQuery,
            variables: {
                shareToken,
            },
        });
        return response;
    } catch (e: any) {
        console.group(
            ...e.response.errors.map((err: any) => console.debug(err)),
            console.debug('(in getSharedWishlistTag)'),
        );
        return e.response.data as GetSharedWishlistTagQuery;
    }
};
