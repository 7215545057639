import { envConfig } from '@/config/env';

export type Market = {
    code: string;
    label: string;
    currency: string;
    languages: string[];
    postalCodeRegex?: RegExp;
    isAlphanumeric: boolean;
    isIndependentMarket: boolean;
    display?: string;
    // if null the market does not support gift cards
    giftCardPaymentMethodId?: number | null;
    dummyPostalCode?: string;
};

export type Markets = Record<string, Market>;

/**
 * Market configuration
 * @deprecated use @/config/markets.ts instead
 */
export const markets: Markets = {
    get de() {
        return {
            code: 'de',
            label: 'Germany',
            currency: 'eur',
            languages: ['en'],
            postalCodeRegex: /\s*\d{5}\s*/,
            isAlphanumeric: false,
            isIndependentMarket: false,
            display: 'German',
            dummyPostalCode: '44287',
        };
    },
    get es() {
        return {
            code: 'es',
            label: 'Spain',
            currency: 'eur',
            languages: ['en'],
            postalCodeRegex: /\s*\d{5}\s*/,
            isAlphanumeric: false,
            isIndependentMarket: false,
            display: 'Spanish',
            dummyPostalCode: '07007',
        };
    },
    get at() {
        return {
            code: 'at',
            label: 'Austria',
            currency: 'eur',
            languages: ['en'],
            postalCodeRegex: /\s*\d{4}\s*/,
            isAlphanumeric: false,
            isIndependentMarket: false,
            display: 'Austrian',
            dummyPostalCode: '1030',
        };
    },
    get fr() {
        return {
            code: 'fr',
            label: 'France',
            currency: 'eur',
            languages: ['en'],
            postalCodeRegex: /\s*\d{5}\s*/,
            isAlphanumeric: false,
            isIndependentMarket: false,
            display: 'French',
            dummyPostalCode: '29200',
        };
    },
    get nl() {
        return {
            code: 'nl',
            label: 'Netherlands',
            currency: 'eur',
            languages: ['nl', 'en'],
            postalCodeRegex: /\s*\d{4}\s?[A-Za-z]{2}\s*/,
            isAlphanumeric: true,
            isIndependentMarket: false,
            display: 'Dutch',
            dummyPostalCode: '1087 HP',
        };
    },
    get be() {
        return {
            code: 'be',
            label: 'Belgium',
            currency: 'eur',
            languages: ['en'],
            postalCodeRegex: /\s*\d{4}\s*/,
            isAlphanumeric: false,
            isIndependentMarket: false,
            display: 'Belgian',
            dummyPostalCode: '7100',
        };
    },
    get fi() {
        return {
            code: 'fi',
            label: 'Finland',
            currency: 'eur',
            languages: ['fi', 'en'],
            postalCodeRegex: /\s*\d{5}\s*/,
            isAlphanumeric: false,
            isIndependentMarket: envConfig.NEXT_PUBLIC_FI !== 'true',
            display: 'Finnish',
        };
    },
    get dk() {
        return {
            code: 'dk',
            label: 'Denmark',
            currency: 'dkk',
            languages: ['da', 'en'],
            postalCodeRegex: /\s*\d{4}\s*/,
            isAlphanumeric: false,
            isIndependentMarket: envConfig.NEXT_PUBLIC_DK !== 'true',
            display: 'Danish',
        };
    },
    get se() {
        return {
            code: 'se',
            label: 'Sweden',
            currency: 'sek',
            languages: ['sv', 'en'],
            postalCodeRegex: /\b\d{3}\s?\d{2}\b/,
            isAlphanumeric: false,
            isIndependentMarket: true,
            display: 'Swedish',
            giftCardPaymentMethodId: 87,
        };
    },
    get no() {
        return {
            code: 'no',
            label: 'Norway',
            currency: 'nok',
            languages: ['nb', 'en'],
            postalCodeRegex: /\s*\d{4}\s*/,
            isAlphanumeric: false,
            isIndependentMarket: true,
            display: 'Norwegian',
        };
    },
    get eu() {
        return {
            code: 'eu',
            label: 'EU',
            currency: 'eur',
            languages: ['en'],
            isAlphanumeric: false,
            isIndependentMarket: true,
            display: 'European',
        };
    },
};

export type Language = { code: string; label: string; flag: string };

export const languages: Record<string, Language> = {
    get en() {
        return {
            code: 'en',
            label: 'English',
            flag: 'gb',
        };
    },
    get fi() {
        return {
            code: 'fi',
            label: 'Finnish',
            flag: 'fi',
        };
    },
    get da() {
        return {
            code: 'da',
            label: 'Danish',
            flag: 'dk',
        };
    },
    get sv() {
        return {
            code: 'sv',
            label: 'Swedish',
            flag: 'se',
        };
    },
    get nb() {
        return {
            code: 'nb',
            label: 'Norwegian',
            flag: 'no',
        };
    },
    get nl() {
        return {
            code: 'nl',
            label: 'Dutch',
            flag: 'nl',
        };
    },
};

export type MarketStatePayload =
    | {
          market: string;
          language: string;
          link?: never;
      }
    | {
          market?: never;
          language?: never;
          link: string;
      };

export type MarketState = {
    locale: string;
    market: Market;
    markets: Record<string, Market>;
    language: Language;
    languages: Record<string, Language>;
    suggested?: Market;
    formatter: Intl.NumberFormat;
    dismissed: boolean;
    contentfulLocale: string;
};
export type InitialMarketState = {
    market: string;
    language: string;
    locale: string | undefined;
};
export type MarketProps = {
    state: MarketState;
    onSave: (payload: MarketStatePayload) => void;
    onClose: () => void;
};
