import type { NavigationLink } from '@components/header/types';
import type { Dispatch, SetStateAction } from 'react';
import { createContext } from 'react';

export type NavigationDirection = 'left' | 'right';

export type MobileMenuContextProps = {
    navigationTree: NavigationLink[];
    setNavigationTree: Dispatch<SetStateAction<NavigationLink[]>>;
    navigationDirection: NavigationDirection;
    setNavigationDirection: Dispatch<SetStateAction<NavigationDirection>>;
};

export const MobileMenuContext = createContext<MobileMenuContextProps>({
    navigationTree: [],
    setNavigationTree: () => {},
    navigationDirection: 'right',
    setNavigationDirection: () => {},
});
