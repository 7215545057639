import { service } from '@server/client';
import type {
    AddWishlistItemMutation,
    AddWishlistItemMutationVariables,
    WishlistAddItemDtoInput,
} from '@server/gql/graphql';
import { addWishlistItemMutation } from '@server/queries/wishlist';

export const addWishlistItem = async ({
    token,
    payload,
}: {
    token: string;
    payload: WishlistAddItemDtoInput;
}) => {
    try {
        const response = await service<
            AddWishlistItemMutation,
            AddWishlistItemMutationVariables
        >({
            query: addWishlistItemMutation,
            variables: {
                token,
                payload,
            },
        });
        return response;
    } catch (e: any) {
        console.group(
            ...e.response.errors.map((err: any) => console.debug(err)),
            console.debug('(in addWishlistItem)'),
        );
        return e.response.data as AddWishlistItemMutation;
    }
};
