import type { ButtonProps } from '@ngg/components';
import { Button as NGGButton } from '@ngg/components';

export default function Button<C extends React.ElementType = 'button'>({
    children,
    ...props
}: ButtonProps<C>) {
    return (
        <NGGButton className="btn-primary" {...(props as any)}>
            {children}
        </NGGButton>
    );
}
