import { graphql } from '@server/gql';

export const deleteWishlistItemTagMutation = graphql(/* GraphQL */ `
    mutation deleteWishlistItemTag(
        $tagId: Int!
        $wishlistItemId: Int!
        $token: String!
    ) {
        deleteWishlistItemTag(
            tagId: $tagId
            wishlistItemId: $wishlistItemId
            token: $token
        ) {
            isError
            data {
                code
                message
                success
            }
            error {
                __typename
                ...problemDetails
                ...genericResponse
            }
        }
    }
`);
