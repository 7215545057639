import { service } from '@server/client';
import type {
    AddAnonymousWishlistItemMutation,
    AddAnonymousWishlistItemMutationVariables,
    InputMaybe,
    WishlistAnonymousAddItemDtoInput,
} from '@server/gql/graphql';
import { addAnonymousWishlistItemMutation } from '@server/queries/anonymousWishlist';

export const addAnonymousWishlistItem = async ({
    wishlistToken,
    payload,
}: {
    wishlistToken?: InputMaybe<string> | undefined;
    payload: WishlistAnonymousAddItemDtoInput;
}) => {
    try {
        const response = await service<
            AddAnonymousWishlistItemMutation,
            AddAnonymousWishlistItemMutationVariables
        >({
            query: addAnonymousWishlistItemMutation,
            variables: {
                wishlistToken,
                payload,
            },
        });
        return response;
    } catch (e: any) {
        console.group(
            ...e.response.errors.map((err: any) => console.debug(err)),
            console.debug('(in addAnonymousWishlistItem)'),
        );
        return e.response.data as AddAnonymousWishlistItemMutation;
    }
};
