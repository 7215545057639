import { graphql } from '@server/gql';

export const addWishlistItemMutation = graphql(/* GraphQL */ `
    mutation addWishlistItem(
        $payload: WishlistAddItemDtoInput!
        $token: String!
    ) {
        addWishlistItem(payload: $payload, token: $token) {
            isError
            data {
                id
                productId
                variantId
            }
            error {
                __typename
                ...problemDetails
                ...genericResponse
            }
        }
    }
`);
