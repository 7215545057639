import { graphql } from '@server/gql';

export const addWishlistItemTagMutation = graphql(/* GraphQL */ `
    mutation addWishlistItemTag(
        $payload: WishlistAddTagAddItemDtoInput!
        $token: String!
    ) {
        addWishlistItemTag(payload: $payload, token: $token) {
            isError
            data {
                id
                productId
                variantId
                tags {
                    id
                    name
                    shareLink
                }
            }
            error {
                __typename
                ...problemDetails
                ...genericResponse
            }
        }
    }
`);
