import { service } from '@server/client';
import type {
    DeleteWishlistTagMutation,
    DeleteWishlistTagMutationVariables,
} from '@server/gql/graphql';
import { deleteWishlistTagMutation } from '@server/queries/wishlist';

export const deleteWishlistTag = async ({
    token,
    tagId,
    deleteUnderlyingItems = false,
}: {
    token: string;
    tagId: number;
    deleteUnderlyingItems?: boolean;
}) => {
    try {
        const response = await service<
            DeleteWishlistTagMutation,
            DeleteWishlistTagMutationVariables
        >({
            query: deleteWishlistTagMutation,
            variables: {
                token,
                tagId,
                deleteUnderlyingItems,
            },
        });
        return response;
    } catch (e: any) {
        console.group(
            ...e.response.errors.map((err: any) => console.debug(err)),
            console.debug('(in deleteWishlistTag)'),
        );
        return e.response.data as DeleteWishlistTagMutation;
    }
};
