import { graphql } from '@server/gql';

export const getWishlistItemQuery = graphql(/* GraphQL */ `
    query getWishlistItem($productId: String!, $token: String!) {
        getWishlistItem(productId: $productId, token: $token) {
            isError
            data {
                id
                productId
                variantId
                tags {
                    id
                    name
                    shareLink
                }
            }
            error {
                __typename
                ...problemDetails
                ...genericResponse
            }
        }
    }
`);
